@import '@src/css/colors.less';

.section__container {
  width: 100%;

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.section__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .section__wrap-left {
    display: flex;
    align-items: center;

    .section__wrap-icon {
      color: @system-blue;
      font-size: 28px;
      width: 28px;
      height: 28px;
    }

    .section__wrap-title {
      margin-left: 14px;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  .section__wrap-right {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .section__wrap-pred-icon {
        color: @system-blue;
        font-size: 28px;
        width: 28px;
        height: 28px;
        margin-right: 0;
      }

      .section__wrap-pred-mark {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .ant-input-number,
    .ant-input-number-focused {
      background: none;
      border: none;
      width: 100px;
      margin-right: 10px;

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input-wrap {
        input {
          height: 40px;
          border: 1px solid rgba(57, 64, 229, 0.5);
          box-sizing: border-box;
          border-radius: 10px;
          text-align: center;
        }
      }
    }

    .section__wrap-total-mark {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }
}

@primary-color: orange;