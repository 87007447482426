@import '@src/css/colors.less';
@import '@src/css/effects.less';

.students__page {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.students__wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin: 30px 0;

  .marked__title {
    align-self: flex-start;
    margin: 0;
  }

  .students_container {
    margin: 0 !important;

    .student_component {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      flex-grow: 0;
      flex-shrink: 1;

      .anticon {
        svg {
          width: 50px;
          height: $width;
        }
      }

      .student_info {
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 10px 25px;

        .student_name,
        .student_username {
          width: 150px;
          font-weight: normal;
          margin: 0 !important;
          padding: 0 !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .ant-btn-link {
            padding: 0;
          }
        }

        .student_username {
          color: @system-dark-gray;
        }
      }
    }
  }
}

@primary-color: orange;