.comment__bar {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  background: #f4f4f4;
  border: 2px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0 5px 0 rgba(0, 122, 255, 0.4);
  border-radius: 15px;

  .comment__input,
  .comment__input:focus {
    border: none;
    background: none;
    box-shadow: none;
  }

  .comment_icon_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px;

    .comment__icon {
      color: #007aff;
      font-size: 16px;
    }
  }
}

@primary-color: orange;