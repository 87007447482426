@import '@src/css/colors.less';
@import '@src/css/effects.less';

.nav__header {
  background-color: #fff;
  border-bottom: 2px solid #f5f5f5;

  .ant-page-header-heading-title {
    font-size: 30px;
  }

  .ant-page-header-footer {
    margin-top: 16px;
    margin-left: -20px;
  }
}

@primary-color: orange;