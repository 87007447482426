@import '@src/css/colors.less';
@import '@src/css/effects.less';

.settings__page {
  .ant-input,
  .ant-picker,
  .ant-input-number {
    position: relative;
    border: none;
    border-radius: 10px;
    background: @textfield-gray;

    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .ant-input-number {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .ant-list {
    .ant-list-item {
      flex-wrap: wrap;
    }

    .ant-list-item-meta-content {
      width: fit-content;
      min-width: 200px;
    }

    .settings_name_input,
    .settings_date_input,
    .settings_mark_input {
      width: 200px;
      min-width: 100px;
    }

    .ant-empty-image {
      display: none;
    }

    .section__config {
      display: flex;
      flex-direction: row;

      .ant-input-number {
        min-width: 80px;
      }

      .ant-input,
      .ant-input-number {
        margin: 5px;
      }
    }
  }
  .create_button_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    .create_button {
      width: 200px;
      height: 50px;
      font-size: large;
      background: @system-blue;
      border-radius: 10px;
    }
  }
}

@primary-color: orange;