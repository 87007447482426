@system-blue: #007aff;
@place-holder-blue: #88c1ff;
@system-orange: #ff9500;
@system-green: #34c759;
@system-purple: #3940e5;
@light-purple: #7076f8;
@system-danger: #ff0000;
@system-pink: #ff2d55;
@system-dark-gray: #686868;
@system-darker-gray: #252525;
@pantone: #828282;
@system-gray: #a9a9a9;
@textfield-gray: #f1f1f1;
@system-lighter-gray: #dbdbdb;
@placeholder-gray: #dbdbdb;
@system-white: #ffffff;
@blue-to-pink: linear-gradient (180deg, #af1283 0%, #0386ff 96.35%);
@orange-to-pink: linear-gradient (360deg, #f83e81 4.55%, #ffc188 135.33%);
@orange-to-yello: linear-gradient (180deg, #ffd12d 0%, #ff9500 100%);
@blue-to-blue: linear-gradient (180deg, #3998ff 0%, #007aff 100%);
@red-to-red: linear-gradient (180deg, #ff5f5f 0%, rgba(253, 29, 29, 0.92) 100%);
@gray-to-gray: linear-gradient (180deg, #b3b3b3 0%, #898989 100%);
@red-to-orange: linear-gradient (180deg, #fcb045 0%, rgba(253, 29, 29, 0.729167) 100%);
@red-to-blue: linear-gradient (180deg, #fa00ff 0%, #2489ff 97.4%);
@green-to-blue: linear-gradient (180deg, #0094ff 0%, #30aa06 97.4%);
//transparent:
@system-gray-70: rgba (255, 255, 255, 0.7);
