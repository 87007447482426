@import '@src/css/colors.less';

.projects__upload {
  .ant-upload-select-text span {
    &:hover {
      color: #1890ff;
    }

    transition: color 0.2s ease;
  }

  .ant-upload-list {
    display: none !important;
  }
}

.danger__btn {
  color: @system-danger !important;
}

@primary-color: orange;