@import '@src/css/colors.less';

.statement-page {
  .sb_input_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .sb_indicator_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .section__wrap-left {
      margin-top: 40px;
      display: flex;
      align-items: center;

      .section__wrap-icon {
        color: @system-blue;
        font-size: 28px;
        width: 28px;
        height: 28px;
      }

      .section__wrap-title {
        margin-left: 14px;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
    }
  }
}

@primary-color: orange;