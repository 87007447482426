@import '~antd/dist/antd.css';
@import '@src/css/colors.less';
@import '@src/css/effects.less';

.green_icon {
  color: @system-green;
}

.blue_icon {
  color: @system-blue;
}

.orange_icon {
  color: @system-orange;
}

.gray_icon {
  color: @system-gray;
}

.danger_icon {
  color: @system-danger;
}

.site-layout {
  height: 100vh;
  width: 100vw;
  background-color: #fff;

  .ant-layout {
    background-color: inherit;
  }
}

.page-layout {
  height: 100%;
  padding: 30px;
  overflow-y: scroll;
}

.list-page-layout {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .ant-typography {
    margin: 0 0 15px 0 !important;
  }

  .option_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    .ant-spin-nested-loading {
      width: 100%;
    }

    .name_input,
    .date_input {
      border-radius: 10px;
    }

    .mark_input {
      border-radius: 10px;
    }

    .ant-list-item {
      border-bottom: 1.5px solid @textfield-gray !important;
    }

    .anticon {
      svg {
        width: 30px;
        height: $width;
      }
    }

    .list_option {
      font-weight: 600;
      color: @system-blue;
    }

    .list_option_danger {
      color: @system-danger;
    }
  }
}

.link__btn {
  color: black !important;

  &:hover {
    color: #1890ff !important;
  }

  transition: color 0.2s ease !important;
}

@primary-color: orange;