@import '@src/css/colors.less';
@import '@src/css/effects.less';

.ant-modal-content,
.ant-modal-header {
  border: none !important;
  border-radius: 15px !important;
}

.ant-modal-body {
  min-height: 200px;

  textarea {
    border: none;
  }
}

.ant-modal-title {
  font-size: 20px;
}

.ant-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: none !important;
  border-radius: 15px !important;

  button {
    width: 80px;
    height: 40px;
    border: none;
    box-shadow: none;
    border-radius: 7px;
    font-weight: bold;
  }

  .ant-btn-primary {
    background: @system-blue;
  }

  .ant-btn:hover {
    color: @system-blue;
  }

  .modal_btn_danger {
    color: @system-white;
    background: @system-danger;
  }

  .modal_btn_danger:hover {
    color: @system-white;
    background: darken(@system-danger, 3%) !important;
  }

  .ant-btn-primary:hover {
    color: @system-white;
    background: darken(@system-blue, 3%);
  }
}

@primary-color: orange;