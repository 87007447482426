@import '@src/css/colors.less';
@import '@src/css/effects.less';

.side__bar {
  overflow: auto;
  height: 100vh;
  border-right: 2px solid #f5f5f5;

  .ant-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 380px;
    margin-top: 200px;

    &::before,
    &::after {
      content: none;
    }

    .ant-menu-item {
      width: 100%;
      height: fit-content !important;
      line-height: initial;
      padding: 6px 0;
      margin: 0;
      overflow: visible;
      color: @system-blue;

      .side__menu-item {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: inherit;

        .anticon {
          font-size: 30px;
          margin: 0 0 5px 0;
        }

        .side__menu-tiem-text {
          text-align: center;
          font-size: 12px;
          white-space: normal;
          line-height: initial;
          user-select: none;
        }
      }
    }
  }
}

@primary-color: orange;