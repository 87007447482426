@import '@src/css/colors.less';
@import '@src/css/effects.less';

.next__button {
  position: absolute;
  bottom: 200px;
  right: 30px;
  color: @system-purple !important;

  svg {
    width: 36px;
    height: $width;
  }
}

@primary-color: orange;