@import '@src/css/colors.less';
@import '@src/css/effects.less';

.certain-category-search-dropdown {
  display: inline;
}

.ant-select-dropdown {
  position: absolute !important;
  border-radius: 15px !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  -webkit-backdrop-filter: saturate(180%) blur(20px) !important;
  backdrop-filter: saturate(180%) blur(20px) !important;
}

.autocomplete {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  position: relative;
  width: 100%;
  height: 50px;
  margin-top: 15px;
  background: @system-white;
  border: 1px solid rgba(57, 64, 229, 0.5);
  box-shadow: @input-shadow;
  border-radius: 15px;
  resize: none;

  .ant-select-selector {
    width: 100%;
  }

  .ant-input-group-addon {
    border: none;
    background: none;

    span > svg {
      color: @system-gray;
      font-size: 20px;
    }
  }

  input,
  input:focus {
    height: 50px;
    border: none;
    box-shadow: none;
    background: none;
  }

  &:focus {
    background: @system-white;
    border: 1px solid rgba(57, 64, 229, 0.5);
    box-shadow: @input-shadow;
  }
}

@primary-color: orange;